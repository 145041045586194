document.addEventListener("DOMContentLoaded", () => {
  let timer = null;
  let hideTimeout = null;

  const searchLiveResults = document.getElementById("search-live-results");
  const searchQuery = document.getElementById("search-query");

  if (!searchLiveResults || !searchQuery) return;

  function searchLive(query) {
    timer = null;
    fetch("/search/live/?query=" + query)
      .then(response => response.text())
      .then(html => {
        searchLiveResults.innerHTML = html;
      });
  }

  searchQuery.addEventListener("input", evt => {
    const query = evt.target.value;
    if (query.length > 0) {
      searchLiveResults.style.display = "block";
    } else {
      searchLiveResults.style.display = "none";
    }
    searchLiveResults.innerHTML = `<div class="p-3">Searching for ${query}...</div>`;
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      searchLive(query);
    }, 1000);
  });

  searchLiveResults.addEventListener("mouseleave", evt => {
    hideTimeout = setTimeout(() => {
      evt.target.style.display = "none";
      hideTimeout = null;
    }, 1000);
  });

  searchLiveResults.addEventListener("mouseenter", evt => {
    if (hideTimeout) clearTimeout(hideTimeout);
  });
});
